import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

const FeaturesStyle = styled.div`
    width: 100%;
    padding: 120px 0 80px;
    .features {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        h1 {
            font-weight: bold;
            margin-bottom: 60px;
        }
        .features-list {
            width: 100%;
            max-width: 800px;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            h3 {
                margin-bottom: 4px;
                color: var(--dark);
            }
            .feature-icon {
                width: 64px;
                height: 64px;
                background-size: 64px;
                margin-bottom: 10px;
            }
            .feature1 {
                background: url("https://mtimg.onemiao.cn/web-images/icon_nodes.svg") center center no-repeat;
            }
            .feature2 {
                background: url("https://mtimg.onemiao.cn/web-images/icon_code_editor.svg") center center no-repeat;
            }
            .feature3 {
                background: url("https://mtimg.onemiao.cn/web-images/icon_app_services.svg") center center no-repeat;
            }
            .feature4 {
                background: url("https://mtimg.onemiao.cn/web-images/icon_handshake.svg") center center no-repeat;
            }
            .feature5 {
                background: url("https://mtimg.onemiao.cn/web-images/icon_judgement.svg") center center no-repeat;
            }
        }
    }
    @media (max-width: 1025px) {
        padding: 80px 10px 40px;
        .features {
            .features-list {
                justify-content: center;
                .feature {
                    width: 120px;
                }
            }
        }
    }
    @media (max-width: 680px) {
        .features {
            .features-list {
                flex-wrap: wrap;
                justify-content: center;
                align-items: stretch;
                .feature {
                    width: 33%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-bottom: 20px;
                    .feature-icon {
                        width: 48px;
                        height: 48px;
                        background-size: 48px;
                    }
                    h2 { font-weight: bold; }
                }
            }
        }
    }
`

const HomeProductStyle = styled.div`
    width: 100%;
    .home-product-section {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        .home-product-wrap {
            width: 100%;
            max-width: 880px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 80px 0;
            .product-graphic {
                width: 406px;
                height: 306px;
            }
            .product-text {
                display: flex;
                flex-direction: column;
                .title {
                    font-size: 40px;
                    font-weight: normal;
                    span {
                        font-weight: bold;
                        margin-left: 10px;
                    }
                }
                h3 {
                    color: var(--dark);
                    margin-bottom: 24px;
                }
                h2 span {
                    font-weight: bold;
                    margin: 0 10px;
                }
                a {
                    cursor: pointer;
                    width: 180px;
                    height: 60px;
                    border-radius: 30px;
                    background: var(--dark) url("https://mtimg.onemiao.cn/web-images/icon_arrow_right24.svg") right 16px center no-repeat;
                    background-size: 24px;
                    color: white;
                    font-size: 24px;
                    line-height: 60px;
                    text-indent: 32px;
                    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
                }
                ul {
                    display: block;
                    margin: 8px 0 24px;
                    padding-inline-start: 24px;
                    li {
                        line-height: 28px;
                        color: var(--dark);
                        font-size: 16px;
                    }
                }
            }
        }
    }
    .gray {
        background-color: var(--lightGray);
    }
    .home-product-section.crm {
        .product-graphic {
            background: url("https://mtimg.onemiao.cn/web-images/graphic_home_crm.svg") center center no-repeat;
            background-size: cover;
        }
        .title span, h2 span {
            color: var(--primary-color);
        }
        .home-product-wrap .product-text a {
            background-color: var(--primary-color);
        }
    }
    .home-product-section.uts {
        .product-graphic {
            background: url("https://mtimg.onemiao.cn/web-images/graphic_home_uts.svg") center center no-repeat;
            background-size: cover;
        }
        .title span, h2 span {
            color: var(--secondary-color);
        }
        .home-product-wrap .product-text a {
            background-color: var(--secondary-color);
        }
    }
    .home-product-section.edu {
        .product-graphic {
            background: url("https://mtimg.onemiao.cn/web-images/graphic_home_edu.svg") center center no-repeat;
            background-size: cover;
        }
        .title span, h2 span {
            color: var(--blue);
        }
        .home-product-wrap .product-text a {
            background-color: var(--blue);
        }
    }

    @media (max-width: 1025px) {
        .home-product-section {
            padding: 0 20px;
        }
    }
    @media (max-width: 780px) {
        .home-product-section {
            .home-product-wrap {
                flex-direction: column;
                padding: 20px;
                .product-graphic {
                    width: 100%;
                    background-size: contain;
                }
            }
            .product-text {
                padding: 20px 0;
            }
            :nth-child(2) {
                .home-product-wrap {
                    flex-direction: column-reverse;
                }
            }
        }
    }

    @media (max-width: 375px) {
        .home-product-section {
            .home-product-wrap {
                padding: 20px 0;
                .product-text {
                    .title { font-size: 32px; }
                    ul li { font-size: 12px; }
                    a {
                        height: 32px;
                        font-size: 16px;
                        line-height: 32px;
                        width: 140px;
                    }
                }
            }
        }
    }
`

export default function HomeSections() {
    return <div>
        <FeaturesStyle>
            <div className="content-wrap">
                <div className="features">
                    <h1>产品特点</h1>
                    <div className="features-list">
                        <div className="feature">
                            <div className="feature-icon feature1"></div>
                            <h3>医院数据洞察</h3>
                            <h2>具像化</h2>
                        </div>
                        <div className="feature">
                            <div className="feature-icon feature2"></div>
                            <h3>疫苗号源管理</h3>
                            <h2>可视化</h2>
                        </div>
                        <div className="feature">
                            <div className="feature-icon feature3"></div>
                            <h3>患教受众触达</h3>
                            <h2>精准化</h2>
                        </div>
                        <div className="feature">
                            <div className="feature-icon feature4"></div>
                            <h3>预约接种全程</h3>
                            <h2>透明化</h2>
                        </div>
                        <div className="feature">
                            <div className="feature-icon feature5"></div>
                            <h3>学术推广交流</h3>
                            <h2>合规化</h2>
                        </div>
                    </div>
                </div>
            </div>
        </FeaturesStyle>
        <HomeProductStyle>
            <div className="home-product-section gray crm">
                <div className="home-product-wrap">
                    <div className="product-graphic"></div>
                    <div className="product-text">
                        <div className="title">苗通<span>CRM</span></div>
                        <h2>苗通客户关系综合管理系统</h2>
                        <h3>Miao Customer Relationship Management</h3>
                        <h2>苗通<span>CRM</span>帮助疫苗厂商及推广商<br />实现全业务链条的闭环管理</h2>
                        <ul>
                            <li>基于数据洞察医院与医生客户，合理分配资源；</li>
                            <li>智能化管理预约接种全流程，保障用户体验；</li>
                            <li>无缝链接本地化疫苗接种用户，提升商业效益；</li>
                            <li>支持多终端便捷操作和数据可视化，提高工作效率；</li>
                            <li>透明化推广与学术交流活动，满足合规要求。</li>
                        </ul>
                        <Link to="/crm">了解更多</Link>
                    </div>
                </div>
            </div>
            <div className="home-product-section uts">
                <div className="home-product-wrap">
                    <div className="product-text">
                        <div className="title">苗通<span>UTS</span></div>
                        <h2>苗通用户推广渠道源系统</h2>
                        <h3>Miao User Traffic Source</h3>
                        <h2>苗通<span>UTS</span>可以对线上疫苗消费者<br />的需求进行全域链接</h2>
                        <ul>
                            <li>高效解决疫苗推广企业核心业务痛点；</li>
                            <li>为用户平台提供一站式疫苗预约服务；</li>
                            <li>通过私域运营完成科普到接种全流程；</li>
                            <li>多类型客户，多平台整合，高效合规。</li>
                        </ul>
                        <Link to="/crm">了解更多</Link>
                    </div>
                    <div className="product-graphic"></div>
                </div>
            </div>
            <div className="home-product-section gray edu">
                <div className="home-product-wrap">
                    <div className="product-graphic"></div>
                    <div className="product-text">
                        <div className="title">苗通<span>EDU</span></div>
                        <h2>用户宣教及医生学术培训系统</h2>
                        <h3>Miao Education System</h3>
                        <h2>苗通<span>EDU</span>帮助企业进行合规化<br />用户宣教及医生学术培训</h2>
                        <ul>
                            <li>精准完成潜在疫苗消费用户的科普教育；</li>
                            <li>利用线上线下高效方式完成HCP科室会；</li>
                            <li>全证据链留存，实时查看，符合合规要求。</li>
                        </ul>
                        <Link to="/crm">了解更多</Link>
                    </div>
                </div>
            </div>
        </HomeProductStyle>
    </div>
}