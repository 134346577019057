import React, { useState, useRef } from "react";
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

const HomeHeroStyle = styled.div`
  width: 100%;
  background: linear-gradient(99.21deg, #2CB564 0%, #00C885 100%);
  .hero-content {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 30px 20px;
    background: url("https://mtimg.onemiao.cn/web-images/hero_icons.svg") left center no-repeat;
    background-size: auto;
    .hero-text {
      color: white;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      padding: 0 60px 0 20px;
      font-size: bold;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      h1 {
        font-size: 60px;
      }
      h2 {
        font-size: 48px;
        margin-bottom: 10px;
      }
      h3 {
        font-size: 32px;
        font-weight: lighter;
        margin-bottom: 40px;
        margin-top: 10px;
        span {
          font-weight: bold;
          margin-left: 10px;
        }
      }
    }
    .hero-img {
      flex: 1;
    }

    @media (max-width: 1025px) {
      .hero-text {
        h1 {
          font-size: 48px;
        }
        h2 {
          font-size: 32px;
          margin-bottom: 0;
        }
        h3 {
          font-size: 24px;
          margin: 0 0 20px;
        }
      }
    }

    @media (max-width: 780px) {
      flex-direction: column;
      align-items: center;
      padding: 30px 10px 10px;
      .hero-text { padding: 0 0 20px; }
    }

    @media (max-width: 420px) {
      .hero-text {
        h1 { font-size: 32px; }
        h2 { font-size: 24px; }
        h3 { font-size: 18px; }
      }
    }
  }
`

const PlayBtn = styled.div`
  height: 60px;
  padding: 0 16px 0 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--secondary-color);
  border-radius: 30px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  .btn-text {
    font-size: 24px;
    text-shadow: none;
  }
  .play-icon {
    width: 24px;
    height: 24px;
    background: url("https://mtimg.onemiao.cn/web-images/icon_play.svg") center center no-repeat;
    background-size: 24px;
    margin-left: 10px;
  }

  @media (max-width: 641px) {
    height: 40px;
    .btn-text {
      font-size: 16px;
    }
  }
`

const HomeHeroVideo = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.8);
  display: ${({ videoShow }) => videoShow ? "flex" : "none"};
  justify-content: center;
  align-items: center;
  .hero-video {
    width: 100%;
    max-width: 1080px;
    position: relative;
    video {
      width: 100%;
    }
    .close-btn {
      width: 40px;
      height: 40px;
      position: absolute;
      top: 10px;
      right: 10px;
      background: rgba(0, 0, 0, 0.2) url("https://mtimg.onemiao.cn/web-images/remove.svg") center no-repeat;
      background-size: 32px;
      border-radius: 20px;
      cursor: pointer;
      @media (max-width: 480px) {
        width: 24px;
        height: 24px;
        top: 5px;
        right: 5px;
        border-radius: 12px;
        background-size: 16px;
      }
    }
  }
`

export default function HomeHero() {
  const [videoShow, showVideo] = useState(false);

  const videoRef = useRef(null);
  const handleVideoPlay = () => {
    console.log(videoRef);
    videoRef.current.play();
    showVideo(true);
  }
  const cancleVideoPlay = () => {
    videoRef.current.pause();
    showVideo(false);
  }
  
  return <HomeHeroStyle>
    <div className="content-wrap">
      <div className="hero-content">
        <div className="hero-text">
          <h2>疫苗推广领域的</h2>
          <h1>数字化合作伙伴</h1>
          <h3>助力企业<span>降本增效、合规转型</span></h3>
          <PlayBtn onClick={handleVideoPlay}>
            <div className="btn-text">观看产品介绍</div>
            <div className="play-icon"></div>
          </PlayBtn>
        </div>
        <div className="hero-img">
          <StaticImage src="https://mtimg.onemiao.cn/web-images/hero_img.png" alt="苗通SaaS产品" />
        </div>
      </div>
    </div>
    <HomeHeroVideo videoShow={videoShow}>
      <div className="hero-video">
        <video src="https://mtimg.onemiao.cn/web-videos/intro.mp4" controls preload="auto" ref={videoRef}>
        <p>您的浏览器不支持内嵌视频，您可点击此链接观看<a href="https://mtimg.onemiao.cn/web-videos/intro.mp4">苗通SaaS介绍。</a></p>
        </video>
        <div className="close-btn" onClick={cancleVideoPlay}></div>
      </div>
    </HomeHeroVideo>
  </HomeHeroStyle>
}