import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

const HomeResourceStyle = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 120px 20px;
    justify-content: center;
    align-items: center;
    h1 {
        margin-bottom: 40px;
        font-weight: bold;
    }
    .solution-group {
        width: 100%;
        max-width: 1170px;
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        justify-content: center;
    }

    @media (max-width: 820px) {
        padding: 60px 20px;
    }
`;

const ResourceCardStyle = styled.div`
    width: 350px;
    background-color: white;
    border-radius: 6px;
    overflow: hidden;
    margin: 20px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    :hover {
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
        transform: rotate(0.5deg);
    }
    .cover {
        width: 100%;
        height: 148px;
        img {
            width: 100%;
            object-fit: cover;
        }
    }
    .text-wrap {
        width: 100%;
        padding: 10px;
        h4 {
            font-size: 18px;
            margin-bottom: 8px;
        }
        .tags-row {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            span {
                display: block;
                background-color: var(--lightGray);
                border-radius: 2px;
                font-size: 12px;
                color: var(--dark);
                line-height: 20px;
                padding: 0 10px;
                margin-right: 8px;
                margin-bottom: 8px;
            }
        }
        p {
            color: var(--dark);
            font-size: 12px;
        }
    }
    @media (max-width: 820px) {
        width: 100%;
        .cover {
            height: unset;
        }
        .text-wrap {
            padding: 10px 20px 20px;
            h4 { font-size: 24px; }
            p { font-size: 14px; }
        }
    }
    @media (max-width: 420px) {
        .text-wrap {
            h4 { font-size: 18px; }
        }
    }
`

function ResourceCard({ article }) {
    //console.log(article)
    return (
        <ResourceCardStyle key={article.id} id={article.id}>
            <Link to={`/post/${article.id}`} state={{ fromHome: true }}>
                <div className="cover">
                    <img src={article.cover} alt="" />
                </div>
                <div className="text-wrap">
                    <h4>{article.title}</h4>
                    <div className="tags-row">
                        {article.tags.map(tag => (
                            <span key={tag+article.id}>{tag}</span>
                        ))}
                    </div>
                    <p>{article.description}</p>
                </div>
            </Link>
        </ResourceCardStyle>
    );
}

export default function HomeResource({ articles }) {
    return <HomeResourceStyle>
        <h1>资源中心</h1>
        <div className="solution-group">
            {articles.map(article => (
                <ResourceCard key={article.id} article={article} />
            ))}    
        </div>
        <Link to="/resource" className="show-more-resource">查看更多</Link>
    </HomeResourceStyle>
}