import * as React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import HomeHero from "../components/HomeHero"
import HomeSections from "../components/HomeSections"
import HomeSolution from "../components/HomeSolution"
import HomeResource from "../components/HomeResource"

const HomePartnerStyle = styled.div`
  width: 100%;
  background-color: var(--primary-color);
  display: flex;
  flex-direction: column;
  padding: 60px 20px;
  justify-content: center;
  align-items: center;
  h1 {
    color: #014E34;
    margin-bottom: 40px;
    font-weight: bold;
  }
  .partners-group {
    display: flex;
    width: 100%;
    max-width: 1150px;
    flex-wrap: wrap;
    .partner-logo {
      width: 200px;
      height: 140px;
      margin: 0 15px;
      border-radius: 6px;
    }
    .partner1 {
      background: white url("https://mtimg.onemiao.cn/web-images/partner1.png") center no-repeat;
      background-size: 148px;
    }
    .partner2 {
      background: white url("https://mtimg.onemiao.cn/web-images/partner2.png") center no-repeat;
      background-size: 110px;
    }
    .partner3 {
      background: white url("https://mtimg.onemiao.cn/web-images/partner3.png") center no-repeat;
      background-size: 148px;
    }
    .partner4 {
      background: white url("https://mtimg.onemiao.cn/web-images/partner4.png") center no-repeat;
      background-size: 134px;
    }
    .partner5 {
      background: white url("https://mtimg.onemiao.cn/web-images/partner5.png") center no-repeat;
      background-size: 156px;
    }

    @media (max-width: 1180px) {
      max-width: 690px;
      justify-content: center;
      .partner-logo {
        margin: 15px;
      }
    }
    @media (max-width: 500px) {
      background-color: white;
      border-radius: 10px;
      padding: 10px;
      .partner-logo {
        width: 50%;
        height: 100px;
        margin: 0;
        background-size: 50% auto;
      }
      .partner1, .partner5 {
        background-size: 80% auto;
      }
    }
  }
`

const HomeClientStyle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 120px 20px;
  justify-content: center;
  align-items: center;
  h1 {
    font-weight: bold;
  }
  .client-row {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 1px;
    width: 100%;
    max-width: 1080px;
    margin-top: 40px;
    background-color: var(--lightGray);
    .client-logo {
      width: 100%;
      height: 120px;
    }
    .client1 {
      background: white url(https://mtimg.onemiao.cn/web-images/client1.png) center no-repeat;
      background-size: 60%;
    }
    .client2 {
      background: white url(https://mtimg.onemiao.cn/web-images/client2.png) center no-repeat;
      background-size: 60%;
    }
    .client3 {
      background: white url(https://mtimg.onemiao.cn/web-images/client3.png) center no-repeat;
      background-size: 50%;
    }
    .client4 {
      background: white url(https://mtimg.onemiao.cn/web-images/client4.png) center no-repeat;
      background-size: 60%;
    }
    .client5 {
      background: white url(https://mtimg.onemiao.cn/web-images/client5.png) center no-repeat;
      background-size: 80%;
    }
    .client7 {
      background: white url(https://mtimg.onemiao.cn/web-images/client7.png) center no-repeat;
      background-size: 60%;
    }
    .client8 {
      background: white url(https://mtimg.onemiao.cn/web-images/client8.png) center no-repeat;
      background-size: 60%;
    }
    @media (max-width: 845px) {
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    }
    @media (max-width: 480px) {
      grid-template-columns: 100%;
      margin-top: 0;
      max-width: 400px;
      :last-child {
        border-top: 1px solid var(--lightGray);
      }
      :nth-child(2) {
        margin-top: 40px;
      }
      .client-logo {
        background-size: 40%;
        height: 80px;
      }
      .client1 { background-size: 40%; }
      .client2 { background-size: 40%; }
      .client3 { background-size: 32%; }
      .client4 { background-size: 36%; }
      .client5 { background-size: 60%; }
      .client7 { background-size: 40%; }
      .client8 { background-size: 40%; }
    }
  }
`

function parsePosts(orginPosts) {
  const newPosts = orginPosts.map(post => {
    const typeTags = post.node.properties.TypeTags.value;
    const postTags = [post.node.properties.publicTime.value.start]
    typeTags.map(tag => (postTags.push(tag.name)))
    
    return {
      title: post.node.title,
      description: post.node.properties.Description.value,
      id: post.node.id,
      tags: postTags,
      cover: post.node.properties.cover.value,
    }
  })

  return newPosts
}

export default function HomePage({ data }) {
  console.log(data);
  const posts = data.solutions.edges;
  const solutionPosts = parsePosts(posts);

  const resources = data.resources.edges;
  const resourcePosts = parsePosts(resources);
  
  return <div>
      <HomeHero />
      <HomeSections />
      <HomeSolution articles={solutionPosts} />
      <HomeResource articles={resourcePosts} />
      <HomePartnerStyle>
        <h1>战略伙伴</h1>
        <div className="partners-group">
          <div className="partner-logo partner1">
          </div>
          <div className="partner-logo partner2">
          </div>
          <div className="partner-logo partner3">
          </div>
          <div className="partner-logo partner4">
          </div>
          <div className="partner-logo partner5">
          </div>
        </div>
      </HomePartnerStyle>
      <HomeClientStyle>
        <h1>企业客户</h1>
        <div className="client-row">
          <div className="client-logo client1"></div>
          <div className="client-logo client2"></div>
          <div className="client-logo client3"></div>
          <div className="client-logo client4"></div>
        </div>
        <div className="client-row">
          <div className="client-logo client5"></div>
          <div className="client-logo client7"></div>
          <div className="client-logo client8"></div>
        </div>
      </HomeClientStyle>
  </div>
}

export const query = graphql`
  query {
    solutions: allNotion(
      sort: {fields: properties___publicTime___value___start, order: DESC}
      filter: { 
        properties: { 
          PageType: {value: {elemMatch: { name: { eq: "Solution" } } } },
          ContentType: {value: {elemMatch: { name: { eq: "Article" } } } },
          AtHomePage: {value: {name: { eq: "yes" } } }
        } 
      }
    ) {
      edges {
        node {
          id
          title
          properties {
            cover {
              value
            }
            TypeTags {
              value {
                name
                color
                id
              }
            }
            publicTime{
              value {
                start(formatString: "YYYY-MM-DD")
              }
            }
            Description {
              value
            }
          }
        }
      }
    }
    resources: allNotion(
      sort: {fields: properties___publicTime___value___start, order: DESC}
      filter: {
        properties: {
          PageType: {value: {elemMatch: { name: { eq: "Resource" } } } },
          ContentType: {value: {elemMatch: { name: { eq: "Article" } } } },
          AtHomePage: {value: {name: { eq: "yes" } } }
        }
      }
    ) {
      edges {
        node {
          id
          title
          properties {
            cover {
              value
            }
            TypeTags {
              value {
                name
                color
              }
            }
            publicTime {
              value {
                start(formatString: "YYYY-MM-DD")
              }
            }
            Description {
              value
            }
          }
        }
      }
    }
  }
`